import React from "react"
import { graphql } from 'gatsby'

import NewsSingleComponent from "./NewsSingleComponent"

class NewsSingle extends React.Component {
  render() {
    return (
      <NewsSingleComponent
        data={this.props.data}
        section={this.props.pageContext.section}
      />
    )
  }
}

export default NewsSingle

//
// Each work fields
//
export const query = graphql`
  query NewsQuery($slug: String!) {
    news: contentfulNews(slug: { eq: $slug }) {
      ...newsFragment
    }
  }
`
