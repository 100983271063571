import React from "react"
import { Link } from "gatsby"
import Fade from "react-reveal/Fade"

import FigureBox, { boxFormats } from "../../components/FigureBox"
import InlinePlayer from "../../components/InlinePlayer"
import { formatedDate } from "../../utils/date"

const NewsHeader = props => {
  let {
    title,
    subheadline,
    slug,
    office,
    publishDate,
    mainImage,
    externalLink,
    videoPreview,
  } = props.news.node !== undefined ? props.news.node : props.news

  const header = () => (
    <Fade
      bottom={props.bottom}
      duration={1000}
      delay={props.delay}
      distance="10%"
    >
      <header className="news__single__header row">
        <h1 className="news__title h2 col-11 col-lg-9 col-xl-7 mx-auto text-center">
          {title}
        </h1>
        {subheadline && (
          <h2 className="h4 news__subheadline col-11 col-md-9 col-xl-7 mx-auto text-center">
            {subheadline}
          </h2>
        )}
        <hgroup className="news__meta col-12 text-center">
          {office != null && (
            <span className="office__shortname">{office.shortName}</span>
          )}
          {publishDate && <date>{formatedDate(publishDate)}</date>}
        </hgroup>
      </header>
    </Fade>
  )

  const figure = () => (
    <Fade
      bottom={props.bottom}
      duration={1200}
      delay={props.delay}
      distance="10%"
    >
      <figure className="news__single__main_image">
        {/* FIXME: this has been depretaced, news always use small ContentPreview format */}
        {/* on list page, always show image only */}
        {mainImage &&
          !props.isSingle && (
            <FigureBox
              source={mainImage}
              format={boxFormats.superScope}
            />
          )}
        {/* on single page, with no videoPreview */}
        {mainImage &&
          !videoPreview &&
          props.isSingle && (
            <FigureBox
              source={mainImage}
              format={boxFormats.auto}
              type={`inline`}
            />
          )}
        {/* on single page with videoPreview */}
        {videoPreview &&
          props.isSingle && (
            <InlinePlayer
              src={videoPreview.file.url}
              canToggleFullScreen={false}
            />
          )}
      </figure>
    </Fade>
  )

  if (props.link) {
    // link to attribute handling
    let WrapElement = `a`
    let attributes = {}

    // if link is external, add href and target value
    if (externalLink) {
      attributes = {
        ...{
          href: externalLink,
          target: `_blank`,
        },
      }
    } else {
      WrapElement = Link
      attributes = {
        to: `/${props.contentType}/${slug}`,
      }
    }

    return (
      <article className={`col-12 content__preview ${props.visible}`}>
        <WrapElement {...attributes}>
          {header()}
          {figure()}
        </WrapElement>
      </article>
    )
  } else {
    return (
      <div className="col-12">
        {header()}
        {figure()}
      </div>
    )
  }
}

NewsHeader.defaultProps = {
  bottom: true,
  delay: 250,
  visible: ``,
  link: false,
  isSingle: false,
}

export default NewsHeader
